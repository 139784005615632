var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--notice",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.number')))]), _c('div', {
    staticClass: "tit",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.제목')))]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.작성일')))])])]), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, _vm._l(_vm.fixed, function (notification, index) {
    return _c('div', {
      key: index,
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      staticClass: "num",
      attrs: {
        "role": "cell"
      }
    }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiAlertCircleOutline))])], 1), _c('div', {
      staticClass: "tit font-weight-bold",
      attrs: {
        "role": "cell"
      }
    }, [_c('a', {
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_vm._v(_vm._s(notification.subject))])]), _c('div', {
      staticClass: "date font-weight-bold",
      attrs: {
        "role": "cell"
      }
    }, [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(notification.createdAt.toDate()))])])]);
  }), 0), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, _vm._l(_vm.notifications, function (notification, index) {
    return _c('div', {
      key: index,
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      staticClass: "num",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1))]), _c('div', {
      staticClass: "tit",
      attrs: {
        "role": "cell"
      }
    }, [_c('a', {
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_vm._v(_vm._s(notification.subject))])]), _c('div', {
      staticClass: "date",
      attrs: {
        "role": "cell"
      }
    }, [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(notification.createdAt.toDate()))])])]);
  }), 0)]), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }