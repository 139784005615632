<template>
<div class="board">
    <v-expansion-panels accordion>
        <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
            <v-expansion-panel-header>
                <p class="category">{{ $t(`faq.type['${faq.type}']`) }}</p>
                <p class="icon"></p>
                <p class="tit">{{ faq.subject }}</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p class="icon"></p>
                <p class="txt" v-html="faq.content.replace(/\n/g, '<br>')"></p>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</div>
</template>

<script>
import api from "@/api";

export default{
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            filter: {
                type: "회원서비스",
            },
            faqs: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search(){
            try{
                var { summary, faqs } = await api.v1.center.faqs.gets({
                    params: this.filter
                });

                this.faqs = faqs;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

$border-row: #e6e6e6;

.board{
	.v-expansion-panels { border-top: 1px solid $color-primary; border-bottom: 1px solid $border-row; }
	.v-expansion-panel:before { content: none; }
	.v-expansion-panel-header{
		.category { flex: 0 0 90px; height: 25px; line-height: 23px; text-align: center; font-size: 14px; color: #999999; border: 1px solid #999999; border-radius: 3px; }
		.icon { flex: 0 0 24px; position: relative; margin: 0 12px 0 24px; height: 24px; border: 2px solid $color-primary; border-radius: 50%; }
		.icon::before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url('/images/icon/faq-q.svg') no-repeat center / auto 11px; }
		.tit { line-height: 1.6; font-size: 18px; }
	}
	::v-deep .v-expansion-panel-content__wrap{
		display: flex; padding-top: 20px; background-color: #f9f9f9; border-top: 1px solid $border-row;
		.icon { flex: 0 0 24px; position: relative; margin: 0 12px 0 114px; height: 24px; border: 2px solid #999999; border-radius: 50%; }
		.icon::before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url('/images/icon/faq-a.svg') no-repeat center / auto 11px; }
	}
	::v-deep .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child):after { border-color: $border-row; }
	::v-deep .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon { font-size: 30px; }
	::v-deep .theme--light.v-expansion-panels .v-expansion-panel-header--active .v-expansion-panel-header__icon .v-icon { color: $color-primary; }
}
@media(max-width:1200px){
    
}
@media(max-width:768px){
    .board{
        .v-expansion-panel-header{
            flex-direction: column; align-items: flex-start; padding: 16px 44px 16px 20px;
            .category { flex: 0 0 25px; width: 85px; font-size: 13px; }
            .icon { display: none; }
            .tit { margin-top: 10px; line-height: 1.5; font-size: 16px; }
        }
        ::v-deep .v-expansion-panel-header__icon { position: absolute; top: 54px; right: 8px; }
        ::v-deep .v-expansion-panel-content__wrap{
            .icon { display: none; }
        }
        ::v-deep .v-expansion-panel-content__wrap{
            padding: 20px;
            .txt { font-size: 14px; }
        }
    }
}
</style>