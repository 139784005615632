<template>
	<client-page class="wrap wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual :visual="$t('common.공지사항')"></sub-visual>

		<div class="contents">
			<div class="ct">
				<!-- <v-container></v-container> -->
				<!-- 1. 게시판 리스트: 베이직 스킨 -->
				<notification-list v-if="!$route.params._notification"></notification-list>
				<notification-view v-else></notification-view>
				<!-- 2. 게시판 작성: 베이직 스킨 -->
				<!-- <board-basic-input></board-basic-input> -->
				<!-- 3. 게시판 뷰: 베이직 스킨 -->
				<!-- <board-basic-view></board-basic-view> -->
            </div>
		</div>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default{
	components: {
        ClientPage,
        SubVisual,
		NotificationList,
		NotificationView
	},
}
</script>
