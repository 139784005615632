var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.공지사항')
    }
  }), _c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "ct"
  }, [!_vm.$route.params._notification ? _c('notification-list') : _c('notification-view')], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }