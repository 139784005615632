var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board"
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.faqs, function (faq, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_c('p', {
      staticClass: "category"
    }, [_vm._v(_vm._s(_vm.$t(`faq.type['${faq.type}']`)))]), _c('p', {
      staticClass: "icon"
    }), _c('p', {
      staticClass: "tit"
    }, [_vm._v(_vm._s(faq.subject))])]), _c('v-expansion-panel-content', [_c('p', {
      staticClass: "icon"
    }), _c('p', {
      staticClass: "txt",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })])], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }