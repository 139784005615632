<template>
    <client-page class="wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual :visual="$t('common.자주 묻는 질문')"></sub-visual>

		<div class="contents">
			<div class="ct">
				<faq-list></faq-list>
			</div>
		</div>
	</client-page>
</template>

<script>

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import FaqList from "@/components/client/center/faqs/faq-list.vue";

export default{
	components: {
        ClientPage,
        SubVisual,
		FaqList,
    },
}
</script>
