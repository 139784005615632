<template>
    <div class="board-container">
        <div role="table" class="board-table board-table--notice">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader" class="num">{{$t('common.number')}}</div>
                    <div role="columnheader" class="tit">{{$t('common.제목')}}</div>
                    <div role="columnheader" class="date">{{$t('common.작성일')}}</div>
                </div>
            </div>
            <div role="rowgroup">
                <div v-for="(notification, index) in fixed" :key="index" role="row">
                    <div role="cell" class="num"><v-icon>{{ mdiAlertCircleOutline }}</v-icon></div>
                    <div role="cell" class="tit font-weight-bold"><a @click="$router.push(`${$route.path}/${notification._id}`)">{{ notification.subject }}</a></div>
                    <div role="cell" class="date font-weight-bold"><span class="date">{{ notification.createdAt.toDate() }}</span></div>
                </div>
            </div>
            <div role="rowgroup">
                <div v-for="(notification, index) in notifications" :key="index" role="row">
                    <div role="cell" class="num">{{ (page-1)*limit + index + 1 }}</div>
                    <div role="cell" class="tit"><a @click="$router.push(`${$route.path}/${notification._id}`)">{{ notification.subject }}</a></div>
                    <div role="cell" class="date"><span class="date">{{ notification.createdAt.toDate() }}</span></div>
                </div>
            </div>
        </div>
        <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>
    </div>
</template>

<script>
import api from "@/api";
import PaginationComponent from "@/components/client/control/pagination-component.vue";
import { mdiAlertCircleOutline } from '@mdi/js';
export default {
    components: {
        PaginationComponent
    },
    props: {
        value: { type: String, default: null }
    },
    data() {
        return {
            mdiAlertCircleOutline,

            filter: { isNotice: false },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            fixed: [],
            notifications: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init() {
            await this.search();
		},
        async search() {
            this.$router.push({
                query: Object.assign({}, this.query, { page: this.page })
            });

            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: {
                    limit: 0
                },
                params: { isNotice: true }
            });

            this.fixed = fixed;

            var { summary, notifications } = await api.v1.center.notifications.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.notifications = notifications;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    }
}
</script>
